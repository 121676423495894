<div>
    <div class="container brand-heading-container">
        <h1 class="brand-heading brand-heading--inverse">
            <img class="brand-logo" alt="" src="https://www.chatbx.eu/img/chatbx.png" width="128px" height="128px" />
            <span class="brand-name">
                <span class="brand-name__text">chatbx.eu</span>
                <p class="byline">Decentralized Instant Messaging with XMPP.</p>
            </span>
        </h1>
        <p class="brand-subtitle">Powered by <a target="_blank" rel="nofollow" href="https://conversejs.org">converse.js</a> {{{o.version_name}}}</p>
    </div>
</div>
